import "./style.scss";
import ModuleBlock from "../../../shared/moduleBlock";
import data from "./data";

const Monitor = () => {
  return (
    <section id="monitor" className="monitor__section">
      <ModuleBlock data={data} />
    </section>
  );
};

export default Monitor;
