import newsUnios from "../../../../assets/images/main/news-unios.png";
import newsVersion from "../../../../assets/images/main/version.png";
import newsCase from "../../../../assets/images/main/case.png";
import secure from "../../../../assets/images/main/piqsels.com-id-zbpzq.jpg";
import min from "../../../../assets/images/main/минцифры2.jpg";
import minq from "../../../../assets/images/main/photo_2024-07-18_20-36-34.jpg";
import minqa from "../../../../assets/images/main/image11-min.png";

const newsData = [
  {
    id: 7,
    date: "28 августа 2024 года",
    title: "С 1 января 2025 года госкомпании должны перейти на отечественное программное обеспечение.",
    image: minqa,
    content: [
      {
        text: "Именно такие сроки установлены Министерством цифрового развития РФ после поручения президента подготовить переход компаний с государственным участием на российский софт разных классов. Для эффективной диспетчеризации и мониторинга оборудования на промышленных объектах, рекомендуем обратить внимание на программное обеспечение Unios, включенное в реестр отечественного ПО Минцифры.",
        list: [],
      }
    ]
  },
  {
    id: 6,
    date: "22 июля 2024 года",
    title: "Unios вошёл в Топ-30 проектов акселератора INDUSTRIX 2024",
    image: minq,
    content: [
      {
        text: "С гордостью сообщаем, что наша команда успешно прошла все этапы участия в акселерационной программе «Газпром нефть» - INDUSTRIX 2024. Мы вошли в топ-30 лучших проектов в области разведки и добычи, отобранных из более чем 500 заявок со всей России.",
        list: [],
      },
      {
        text: "Высокая оценка нашего проекта от экспертов и представителей бизнес-заказчиков подтверждают важность и перспективность нашего решения, а также конкурентоспособность и инновационность нашего проекта.",
        list: [],
      },
    ],
    link: {
      title: "Сертификат",
      href: "/photo_2024-07-22_14-26-51.jpg",
    },
  },
  {
    id: 5,
    date: "7 марта 2024 года",
    title: "ПО Unios 3.0 включено в реестр Минцифры",
    image: min,
    content: [
      {
        text: "Программное обеспечение Unios 3.0 включено в Единый Реестр Российского Программного Обеспечения для электронных вычислительных машин и баз данных и является зарегистрированной промышленной версией программного продукта.",
        list: [],
      },
    ],
    link: {
      title: "Ссылка на реестр ПО >>",
      href: "https://reestr.digital.gov.ru/reestr/2196187/?sphrase_id=4240748",
    },
  },
  {
    id: 4,
    date: "03 февраля 2024 года",
    title: "В версии Unios 3.0 учтены требования информационной безопасности",
    image: secure,
    content: [
      {
        text: "В целях защиты и исключения случаев утечки информации мы разработали и реализовали перечень требований к информационной безопасности программного обеспечения.",
        list: [],
      },
    ],
    link: {
      title:
        "Перечень мероприятий по обеспечению информационной безопасности >>",
      href: "/Перечень_мероприятий_по_обеспечению_информационной_безопасности.pdf",
    },
  },
  {
    id: 3,
    date: "23 Декабря 2022 года",
    title: "До конца 2023 года переходим на версию 3.0",
    image: newsUnios,
    content: [
      {
        text: "Команда FTXCom выпускает новую улучшенную версию платформы.",
        list: [],
      },
      {
        text: "Выпуск планируется в середине четвертого квартала 2023 год",
        list: [],
      },
    ],
  },
  {
    id: 2,
    date: "19 Декабря 2022 года",
    title: "Обновление ПО \nдо версии Beta 2.2.0",
    image: newsVersion,
    content: [
      {
        text: "Платформа Unios обновилась до версии Beta 2.2.0\nБыли внесены следующие изменения:",
        list: [],
      },
      {
        text: "",
        list: [
          "Заявки и работа сервиса",
          "Повышена стабильность работы платформы",
          "Система адаптирована для сбора данных с контроллеров Siemens SIMATIC",
        ],
      },
    ],
  },
  {
    id: 1,
    date: "17 Декабря 2021 года",
    title: "Кейс Тех. Обслуживания и Ремонта",
    image: newsCase,
    content: [
      {
        text: "Для удобства пользования нашей платформой мы выделили отдельный кейс ТОиР, как отдельный инструментарий для работы с сервисным и плановым обслуживанием оборудования:",
        list: [
          "Контроль Исполнителей.",
          "Автоматическая загрузка регламентов",
          "Календарь ТОиР, и автоматизация контроля выполнения работ",
          "Менеджер заявок, задач и отчетности в 1.С и SAP",
        ],
      },
    ],
  },
];

export default newsData;
