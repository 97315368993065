import "./style.scss";
import Join from "../../shared/join";
import Borders from "../../shared/borders";
import about from "../../assets/images/main/scada/scada.png";
import one from "../../assets/images/main/about/one.jpg";
import two from "../../assets/images/main/about/two.jpg";
import three from "../../assets/images/main/about/three.jpg";
import four from "../../assets/images/main/about/four.jpg";
import { HelmetProvider } from "react-helmet-async";

const PageRequest = ({ togglePopup }) => {

  const teamsList = [
    {
      anchor: 'turkunov-stanislav',
      name: 'Туркунов Станислав',
      img: one,
      post: 'Chief Executive Officer',
      desctiption: [
        'Кандидат Технических Наук.',
        'Ген. директор организации, выполняющей работы по реализации крупных инфраструктурных проектов с более чем 20-летним стажем'
      ]
    },
    {
      anchor: 'malkov-anton',
      name: 'Мальков Антон',
      img: two,
      post: 'Chief Financial Officer',
      desctiption: [
        'Более 20 лет опыта управления.',
        'Реализовано более 100 успешных проектов в области промышленного и банковского ПО, систем обработки и анализа данных, CAD-систем, высоконагруженных систем реального времени, геоинформационных систем, систем управления предприятием'
      ]
    },
    {
      anchor: 'barchenko-nikolaj',
      name: 'Барченко Николай',
      img: three,
      post: 'Chief technical officer',
      desctiption: [
        'Архитектор и разработчик высоконагруженных систем с более чем 20-летним стажем.',
        'Разработка и внедрение систем мониторинга и оперативной интерпретации данных промышленного оборудования в нефтегазовой отрасли'
      ]
    },
    {
      anchor: 'turkunov-aleksandr',
      name: 'Туркунов Александр',
      img: four,
      post: 'Chief Product Officer',
      desctiption: [
        'Менеджер и разработчик интерфейсов систем обработки и анализа данных, CAD-систем и систем управления предприятием с более чем 7 летним стажем'
      ]
    },
  ]

  return (
    <>
      <HelmetProvider>
        <title>О нас</title>
        <meta
          name="description"
          content="Инструмент для планирования,
создания и контроля выполнения работ по техническому обслуживанию и ремонту. Настройка бизнес–процессов, веб-интерфейс, кобильные рабочие места"
        />
      </HelmetProvider>

        <section className="about-page about-page__section">
          <div className="about-page__container container">
            <div className="about-page__content">
              <h1 className="about-page__title">О компании</h1>
              <h4>UNIOS</h4>
              <p>Сервис UNIOS является отечественным решением диспетчеризации и мониторинга оборудования на промышленных объектах в России.</p>
              <p>Программное обеспечение Unios включено в Единый Реестр Российского Программного Обеспечения для ЭВМ и БД и является зарегистрированной промышленной версией программного продукта и имеет номер - № 21772</p>
              <div className="about-page__hero">

                <img src={about} alt="" />
                <div className="side-text">
                  <p>Интеграционная платформа UNIOS предназначена для комплексного мониторинга и обслуживания различных автоматизированных систем и устройств предприятия. Платформа решает следующие задачи:</p>
                  <ul>
                    <li>Единообразное отображение и анализ данных SCADA систем предприятия</li>
                    <li>Гибкий автоматизированный бизнес - процесс техобслуживания и ремонта</li>
                    <li>Оперативная постановка задач и контроль выполнения работ Исполнителями в реальном времени</li>
                    <li>Непрерывный обмен документами и информацией с основными системами предприятия</li>
                  </ul>
                </div>
              </div>

              <h4>Наша команда</h4>
              <ul className="about-page__our-team">
                {teamsList.map((e, i) => (
                  <li id={e.anchor} className="our-person" key={i}>
                    <div className="our-img">
                      <img src={e.img} alt="" />
                    </div>

                    <div className="our-content">
                      <div className="our-name"><span>{e.name}</span></div>
                      <div className="our-post"><span>{e.post}</span></div>
                      <div className="our-description">
                        {e.desctiption.map((el, idx) => (
                          <p key={idx}>{el}</p>
                        ))}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="request-form__borders">
              <Borders />
            </div>
            <div className="about-page__background"></div>
          </div>
        </section>
      <Join className="reques_join" togglePopup={togglePopup} />
    </>
  );
};

export default PageRequest;
